import React, { useRef, useState, useContext } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { login,loggedUser,loginWithGoogle } from  "../components/FirebaseAuth"
import { Link, navigate } from "gatsby"
import { PageLayout } from "../components"
import { SEO } from "../utils"
import ThemeContext from "../utils/theme"

export default function Login() 
{
  const emailRef = useRef()
  const passwordRef = useRef()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const { dark } = useContext(ThemeContext)

  async function callLogin()
  {
    await login(emailRef.current.value, passwordRef.current.value).then((resp) => 
    {
      let profileInfo = loggedUser()
      let neededData = {"email":profileInfo.email,"displayName":profileInfo.displayName,"uid":profileInfo.uid}
      //console.log("profile is",neededData)
      localStorage.setItem('profileData', JSON.stringify(neededData) )
      navigate(`/`)

    }).catch((error) => 
    {
      setError(String(error) )
    })
  }
  async function callGoogleLogin()
  {
    await loginWithGoogle().then((resp) => 
    {
      //console.log(resp)
      let profileInfo = loggedUser()
      let neededData = {"email":profileInfo.email,"displayName":profileInfo.displayName,"uid":profileInfo.uid}
      //console.log("profile is",neededData)
      localStorage.setItem('profileData', JSON.stringify(neededData) )
      navigate(`/`)
    }).catch((error) => 
    {
      setError(String(error) )
    })
  }

  async function handleSubmit(e) 
  {
    e.preventDefault()
    try 
    {
      setError("")
      setLoading(true)
      callLogin()
    } 
    catch 
    {
      setError("Failed to log in")
    }
    setLoading(false)
  }

  return (
    <>
    <PageLayout>
      <SEO title="Login" />
      <Container className="d-flex align-items-center justify-content-center" >
      <div className="w-100 text-color" style={{ maxWidth: "400px" }}>
        <Card style={{ borderColor:`${dark ? '#333' : '#ddd'}`,borderWidth:"3px" }}>
          <Card.Body>

            <h2 className="text-center mb-4">Login</h2>
            
            {error && <Alert style={{fontWeight:"900",backgroundColor:"#d9534f",color:"white",borderColor:"#d9534f"}}>{error}</Alert>}

            <Button disabled={loading} style={{backgroundColor:'#00ff00',borderWidth:'0px'}} onClick={callGoogleLogin}>
              Login with Google 
            </Button>
            <br/><br/>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <br/>
              <Form.Group id="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <br/>
              <Button disabled={loading} type="submit" style={{margin:"0 auto"}}>
                Login
              </Button>
            </Form>
            <div className="w-100 text-center mt-3">
              <Link to="/ForgotPassword">Forgot Password?</Link>
            </div>

          </Card.Body>
        </Card>

        <div className="w-100 text-center mt-2">
          Need an account? <Link to="/Signup">Sign Up</Link>
        </div>
        <br/><br/>
      </div>
      </Container>
    </PageLayout>
    </>
  )
}